import React, { Component } from 'react'
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import imgpaymentcheck from './paymentcheck.png';
import imgpaymentuncomplete from './paymentuncomplete.png';
import imgpaymentcomplete from './paymentcomplete.png';

export default class ScanQr extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: false, qrImgSrc: '', charge: {}, isSuccess: false, isFail: false };

    }

    componentDidMount() {
        this.createPaymentSource();
        this.checkChargeStatus(true)
        this.renderTimer(true)
    }

    componentWillUnmount() {
        console.log('unmount')
        this.checkChargeStatus(false)
    }

    navigateToMain = () => {
        window.location.replace('/');
    }

    render() {
        return (
            <div className="div-list-qr" style={{ width: '100%', height: '100vh', display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
                {!this.state.isFail && <div style={{ width: "800px", height: "400px", }}>
                    {this.state.qrImgSrc === '' && <div style={{ marginTop: '200px' }}><CircularProgress /></div>}
                    {this.state.qrImgSrc !== '' && <img style={{ width: '380px' }} src={this.state.qrImgSrc} alt="qrCode" />}
                </div>}
                <div>{this.state.isFail && this.renderFailScreen()}</div>
                <div> </div>
                <div>กรุณาทำรายการภายในเวลา {this.state.countdownTimer} วินาที<br/>หลังจากทำรายการเสร็จ ใช้เวลาประมาณ 10 วินาที ในการตรวจสอบ</div>
                <div onClick={() => this.navigateToMain()}>Developed by Booyah Team</div>

            </div>
        )
    }

    renderFailScreen = () => {
        return <div style={{ width: "100%", height: "400px", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Box>
                <div>ทำรายการล้มเหลว กรุณาลองใหม่อีกครั้ง</div>
                <Button style={{ marginTop: '25px' }} variant="outlined" onClick={() => window.location.reload()}>ตกลง</Button>
            </Box>

        </div>
    }

    renderTimer = () => {

        let myTimer;
        let countTimer = 90;
        let showTimer;
        myTimer = setInterval(() => {
            
            showTimer = countTimer
            console.log(countTimer);

            this.setState({
                countdownTimer: showTimer
            })


            if (countTimer<1) {
                clearInterval(myTimer);
                
                this.setState({ loading: true })
                
                this.setState({
                    qrImgSrc: imgpaymentcheck                           
                })


                axios.post('https://api.event.booyah.cloud/retrieve-charge', {
                    chargeId: this.state.charge.id
                }).then(res => {
                    this.setState({ isSuccess: res.data.response.status === 'successful' })
                    if (!!this.state.isSuccess) {
                        console.log("final check");
                        this.toggleDslr();
                        return;
                    }else{
                        console.log("end check");
                        // no payment
                        setTimeout(() => {
                            this.setState({
                                qrImgSrc: imgpaymentuncomplete                            
                            })
                        }, 2000); 
                        setTimeout(() => {
                            window.location.replace('/')
                        }, 7000);
                        return;
                    }
                })
                /////////////////////
            }
            countTimer--
        }, 1000);
    }    

    callDslr = () => {
        axios.get('http://localhost:3000/open').then(res => {
            if (res) {
                window.location.replace('/');
            }
        })
    }

    createPaymentSource = () => {

        // time expired
        var now = new Date();
        now = now.setHours(now.getHours() + 7); // timestamp
        now = new Date(now); // Date object
        
        var expired = new Date();
        expired = expired.setSeconds(expired.getSeconds() + 85); // timestamp
        expired = new Date(expired); // Date object
        expired = expired.setHours(expired.getHours() + 7); // timestamp
        expired = new Date(expired); // Date object
        
        expired = now.toISOString().split('.')[0]+"Z";
        // time expired



        const { OmiseCard, Omise } = window
        Omise.setPublicKey('pkey_5uoesdgsphge54eroef');
        Omise.createSource('promptpay', {
            "amount": 12000,
            "currency": "THB",
            expires_at: expired,
        }, (statusCode, response) => {
            this.setState({ loading: true })
            axios.post('https://api.event.booyah.cloud/create-payment', {
                source: response.id
            }).then(res => {
                this.setState({
                    qrImgSrc: res.data.charge.source.scannable_code.image.download_uri,
                    charge: res.data.charge
                })
            })

        });
    }

    toggleDslr = () => {
        this.setState({
            qrImgSrc: imgpaymentcomplete                            
        })
        setTimeout(() => {
            this.callDslr()
         }, 3000);

    }

    checkChargeStatus = (isMouted) => {
        console.log('call')
        let myInterval;
        let count = 0;
        if (isMouted) {
            myInterval = setInterval(() => {
                axios.post('https://api.event.booyah.cloud/retrieve-charge', {
                    chargeId: this.state.charge.id
                }).then(res => {
                    this.setState({ isSuccess: res.data.response.status === 'successful' })
                    if (!!this.state.isSuccess) {
                        clearInterval(myInterval);
                        this.toggleDslr();
                        return;
                    }
                    if (res.data.response.status === 'failed') {
                        this.setState({ isFail: true })
                    }
                    if (count > 10) {
                        clearInterval(myInterval);
                        window.location.replace('/')
                        return;
                    }
                })
                count++
            }, 10000);
        }
        else {
            clearInterval(myInterval);
            return;
        }
    }
}
